import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import styles from "./AnswerMorningForm.module.scss";
import { Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { ANSWER_MORNING_FORTY_EIGHT } from "../../../constant/const";
import { PostAnswerMorningContext } from "../../hooks/reducer/AnswerMorningReducer";
import RadioButton, { RadioResult } from "../../atoms/radio-button/RadioButton";

const AnswerMorningForm = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const navigate = useNavigate();
  const certificationId = query?.get("pageId");
  const seazonId = query?.get("seazon");
  const [answerForm, setAnswerForm] = useState<(string | null)[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    setAnswerForm(
      certificationId === ANSWER_MORNING_FORTY_EIGHT
        ? [...Array<string | null>(48).fill(null)]
        : [...Array<string | null>(25).fill(null)]
    );
  }, []);

  const postAnswerMorningContext = useContext(PostAnswerMorningContext);

  // ラジオボタンが変更されたときのハンドラ
  const handleChange = (e: RadioResult, index: number) => {
    const newAnswers = [...answerForm];
    newAnswers[index] = e.value;
    setAnswerForm(newAnswers);
  };

  const submit = () => {
    if (certificationId == null || seazonId == null) {
      return;
    }
    const ans = answerForm.map((ans, index) => {
      return {
        no: index + 1,
        // よくない
        answer: ans as string,
      };
    });

    const requestAnswers = {
      pageId: certificationId,
      seazon: seazonId,
      answers: ans,
    };
    postAnswerMorningContext?.post?.(requestAnswers);

    navigate(
      `/answer-morning-list?pageId=${certificationId}&seazon=${seazonId}`
    );
  };

  return (
    <div>
      <div className={styles.answerTitile}>
        解答フォーム
        {certificationId !== ANSWER_MORNING_FORTY_EIGHT && <span>(午前Ⅱ)</span>}
      </div>
      <div className={styles.formWrapper}>
        {answerForm.map((key, index) => (
          <div className={styles.formParts} key={index}>
            <RadioButton
              name="answer"
              id={`answer-${index}`}
              value={key}
              items={[
                {
                  display: "A",
                  value: "A",
                },
                {
                  display: "B",
                  value: "B",
                },
                {
                  display: "C",
                  value: "C",
                },
                {
                  display: "D",
                  value: "D",
                },
              ]}
              label={`問${index + 1}`}
              onChange={(e) => handleChange(e, index)}
            ></RadioButton>
          </div>
        ))}
      </div>
      <div className={styles.answerContainer}>
        <div className={styles.submit}>
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            className={styles.submitButton}
            color="success"
            disabled={answerForm.includes(null)}
            onClick={() => submit()}
          >
            送信
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AnswerMorningForm;

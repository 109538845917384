import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

type PulldownProps = {
  id: string;
  name: string;
  title?: string;
  items: SelectItem[];
  value?: string;
  handlePulldownChange: (event: SelectFormResult) => void;
};

export type SelectItem = {
  name: string;
  value: string;
};

export type SelectFormResult = {
  name: string;
  value: string;
};

const Pulldown = (props: PulldownProps) => {
  const [selectedValue, setValue] = React.useState("");

  const handleChange = (value: string) => {
    props.handlePulldownChange({ value, name: props.name });
    setValue(value);
  };

  return (
    <>
        <FormControl variant="outlined" sx={{ ml: 0,mr:1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-label">{props.title}</InputLabel>
          <Select
            labelId={props.id}
            id={props.id}
            value={selectedValue}
            label="選択"
            sx={{
              bgcolor: "background.paper",
            }}
            onChange={(e) => handleChange(e.target.value)}
          >
            {props.items.map((item, index) => (
              <MenuItem value={item.value} key={`${props.id}-${index}`}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
    </>
  );
};

export default Pulldown;
